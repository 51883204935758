const isDev = false;
const baseUrl = isDev ? "http://192.168.1.127:8000/" : "https://api.gestnex.com/";

export const URLS = {
    // BASE_URL: "http://192.168.100.30:8080/api/",
    BASE_URL: `${baseUrl}api/`,
    BASE_MEDIAS: baseUrl,
    //BASE_URL: "https://test.ovasourcing.ova-consulting.fr/api/",
     //CLIENT_ID: "YOL6YE1L1JJbuq8AtyX6iLeVSvmGFzWRS45Dr4u6",
    //CLIENT_ID: "0En4s1emUqHjhij9P5cHFFg7JbLQLdWKUXef0Xhl",
    PRODUCT_URL: `${baseUrl}/api/`,
    
    AUTHS: {  
      // LOGIN: "auth/mlogin/",
      LOGIN: "token/",
      // REFRESH: "token/refresh/",
      REFRESH: "token/refresh/",
      LOGOUT: "auth/logout/",
      google: "auth/google/",
      REGISTRATION: "accounts/registration/",
      MANAGER_REGISTRATION: "accounts/staff/registration/",
      VERIFY_EMAIL: (key) => `accounts/accounts/confirm-email/${key}/`,
      VERIFY_ACCOUNT: (id) => `/users/verify/${id}/`,
      resetPassword: "accounts/password-reset/",
      resetPasswordAdmin: "accounts/password/admin-reset/",
      me: "users/me/",
      PASSWORD: {
        reset: "auth/password/reset/",
        confirm: "auth/password/reset/confirm/",
      },
      VERIFY: {
        email: "/accounts/email/verification/",
        resend: "/user/accounts/resend-email/",
      }
    },
    ATTENDANT: {
        add: "attendants/",
        list: "attendants/",
        search: (search, filters) => `attendants/?search=${search}${filters}`,
        update: (id) => `attendants/${id}/`,
        delete: (id) => `attendants/${id}/`,
        update: (id) => `attendants/${id}/`,
        import: "attendants/import_attendants/",
        viewTicket: (ticketId) => `attendants/ticket/${ticketId}/`,
    },
    GALLERY: {
        add: "gallery/",
        list: (page) => `gallery/list/?page=${page}`,
        update: (id) => `gallery/${id}/`,
        delete: (id) => `gallery/${id}/`,
    }
  
}